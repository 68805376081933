<template>
  <div class="page page--additions animated_content">
    <sequential-entrance animation="animate__fadeInUp" tag="div">
      <StickyHeader class="animate__animated" title="Twoje zamówienie" with-menu/>
      <div class="col-1 no_animation">
        <div class="col info_container">
          <sequential-entrance animation="animate__fadeInUp" tag="div">
            <div class="image_container animate__animated">
              <img v-if="order.partner && order.partner.image_main" :src="$asset(order.partner.image_main.path)" :alt="order.partner.name">
              <div class="image_logo">
                <img v-if="order.partner && order.partner.image_logo" :src="$asset(order.partner.image_logo.path)" :alt="`Logo ${order.partner.name}`">
              </div>
            </div>
            <InfoBox space class="animate__animated">
              <div class="info_line">
                <span class="product_title" v-if="order.partner">{{ order.partner.name }}</span>
<!--                <OrderStatus :status="order.status"/>-->
<!--                <span class="product_price" v-if="item.price">{{ item.price.toFixed(2).replace('.', ',') }} zł</span>-->
              </div>
              <div class="info_line">
                <OrderStatus v-if="order.status" :status="order.status"/>
                <span v-if="order.created_at">{{ $moment(order.created_at).calendar() }}</span>
              </div>
              <div class="info_line">
                <span>Numer zamówienia</span>
                <span>{{ shortOrderId }}</span>
              </div>
<!--              <p class="info_line" v-if="item.description">{{ item.description }}</p>-->
            </InfoBox>

            <!-- TODO: ocena -->

            <InfoBox space class="animate__animated">
              <div class="info_line">
                Dostawa / odbiór osobisty
              </div>
              <div class="info_line">
                <span v-if="order.delivery_type === 'takeaway'" class="product_title">Odbiór osobisty</span>
                <span v-else class="product_title">Dostawa</span>
              </div>
              <template v-if="order.delivery_address">
                <div class="p-line" v-if="order.delivery_address.company">
                  <i class="alternative-text">Firma</i>
                  <span>{{ order.delivery_address.company }}</span>
                </div>
                <div class="p-line" v-if="order.delivery_address.nip">
                  <i class="alternative-text">NIP</i>
                  <span>{{ order.delivery_address.nip }}</span>
                </div>
                <div class="p-line">
                  <i class="alternative-text">Adres</i>
                  <span>{{ order.delivery_address.street }} {{ order.delivery_address.house_nr }}{{ order.delivery_address.apartment_nr ? `/${order.delivery_address.apartment_nr}` : '' }}</span>
                </div>
                <div class="p-line">
                  <span></span>
                  <span>{{ order.delivery_address.postcode }} {{ order.delivery_address.city }}</span>
                </div>
                <div class="p-line" v-if="order.delivery_address.floor_level">
                  <i class="alternative-text">Piętro</i>
                  <span>{{ order.delivery_address.floor_level }}</span>
                </div>
                <div class="p-line" v-if="order.delivery_address.gate_code">
                  <i class="alternative-text">Kod do bramy</i>
                  <span>{{ order.delivery_address.gate_code }}</span>
                </div>
              </template>
            </InfoBox>

            <InfoBox space class="animate__animated">
              <div class="info_line">
                <span v-if="order.delivery_type === 'takeaway'">Uwagi dotyczące odbioru</span>
                <span v-else>Uwagi dotyczące dostawy</span>
              </div>
              <div class="info_line">
                <span v-if="order.notes" class="product_title">{{ order.notes }}</span>
                <i v-else>Brak</i>
              </div>
            </InfoBox>

            <InfoBox v-for="product in order.products" space class="animate__animated">
              <div class="p-line">
                <div class="product_title">
                  {{ product.quantity }} x {{ product.name }}
                </div>
                <div v-if="product.product && product.product.price" class="product_price">
                  {{ product.product.price.toFixed(2).replace('.', ',') }} zł
                </div>
              </div>
              <div class="p-line" v-if="product.variant">
                <div class="product_variant">{{ product.variant_name }}</div>
                <div v-if="product.variant_price" class="product_addition_price">{{ product.variant_price.toFixed(2).replace('.', ',') }} zł</div>
              </div>
              <div v-for="addition in product.additions" class="p-line">
                <div class="product_addition">{{ addition.name }}</div>
                <div v-if="addition.price" class="product_addition_price">{{ addition.price.toFixed(2).replace('.', ',') }} zł</div>
              </div>
              <div class="hr"></div>
              <div class="p-line">
                <div>Suma</div>
                <div v-if="product.price" class="product_addition_price">
                  {{ parseFloat(product.price).toFixed(2).replace('.', ',') }} zł
                </div>
              </div>
              <div class="p-line" v-if="product.note">
                <i class="alternative-text">Notatka: {{ product.note }}</i>
              </div>
            </InfoBox>

            <InfoBox space class="animate__animated">
              <div class="p-line">
                <div>
                  Razem zamówienie
                </div>
                <div>
                  {{ orderPrice }}
                </div>
              </div>
              <div class="p-line" v-if="order.delivery_type === 'delivery'">
                <div>
                  Koszt dostawy
                </div>
                <div v-if="order.delivery_address">
                  <div v-if="order.discount && order.discount.discount_type === 3">
                    Darmowa dostawa
                  </div>
                  <div v-else-if="order.delivery_cost">
                    {{ parseFloat(order.delivery_cost).toFixed(2).replace('.', ',') }} zł
                  </div>
                  <div v-else>
                    0,00 zł
                  </div>
                </div>
                <div v-else>
                  Wybierz adres
                </div>
              </div>
              <div class="hr"></div>
              <div class="p-line">
                <div class="price_to_pay_title">
                  Suma
                </div>
                <div class="price_to_pay">
                  {{ summaryPrice }}
                </div>
              </div>
            </InfoBox>

            <InfoBox space class="animate__animated">
              <div class="p-line">
                <span>Metoda płatności</span>
              </div>
              <div class="p-line">
                <div v-if="order.payment_method" class="payment_method">
                  <div>{{ order.payment_method.name }}</div>
                  <img v-if="order.payment_method.image" :src="$asset(order.payment_method.image.path)" :alt="order.payment_method.name">
                </div>
              </div>
            </InfoBox>

<!--            <div class="animate__animated">-->
<!--              <h4 v-if="itemMenu.variants_count > 1 || itemMenu.no_empty_variants_count > 1" >Warianty</h4>-->
<!--              <InfoBox space v-if="itemMenu.variants_count > 1 || itemMenu.no_empty_variants_count > 1">-->
<!--              </InfoBox>-->
<!--            </div>-->
          </sequential-entrance>
<!--          <sequential-entrance animation="animate__fadeInUp" tag="div">-->
<!--            <div v-for="category in categories" :key="category.id" class="animate__animated">-->
<!--              -->
<!--            </div>-->
<!--          </sequential-entrance>-->
        </div>
      </div>
      <div class="flex-fill animate__animated"></div>
      <PlainFooter class="animate__animated"/>
    </sequential-entrance>
    <Drawer/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StickyHeader from "@/parts/StickyHeader";
import PlainFooter from "@/parts/PlainFooter";
import Drawer from "@/parts/Drawer";
import InfoBox from "@/parts/InfoBox";
import OrderStatus from "@/parts/OrderStatus";

export default {
  name: "Order",
  components: {
    OrderStatus,
    InfoBox,
    PlainFooter,
    Drawer,
    StickyHeader,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      order: {},
    };
  },
  computed: {
    ...mapGetters([
      'orderDetails',
      'isLogged',
    ]),
    shortOrderId() {
      if (!this.order.id) {
        return '';
      }
      const ex = this.order.id.split('-');
      return ex[ex.length - 1];
    },
    orderPrice() {
      if (!this.order.price) {
        return '';
      }
      return `${parseFloat(this.order.price).toFixed(2).replace('.', ',')} zł`
    },
    summaryPrice() {
      if (!this.order.delivery_cost || this.order.delivery_type === 'takeaway') {
        return `${parseFloat(this.order.price).toFixed(2).replace('.', ',')} zł`;
      }
      return `${(parseFloat(this.order.price) + parseFloat(this.order.delivery_cost)).toFixed(2).replace('.',',')} zł`;
    },
    // itemPrice() {
    //   if (!this.item.price) {
    //     return '';
    //   }
    //   let price = this.item.price;
    //   if (this.form.variant_id) {
    //     this.item.variants.forEach(a => {
    //       if (a.id === this.form.variant_id) {
    //         price += a.price;
    //       }
    //     });
    //   }
    //   this.categories.forEach(a => {
    //     a.additions.forEach(b => {
    //       if (this.form.additions.includes(b.id)) {
    //         price += b.price;
    //       }
    //     })
    //   });
    //   return `${(price * this.form.quantity).toFixed(2).replace('.', ',')} zł`;
    // },
    // itemQuantity() {
    //   if (!this.form.quantity) {
    //     return '';
    //   }
    //   if (this.form.quantity === 1) {
    //     return '1 pozycja';
    //   } else if (this.form.quantity > 1 && this.form.quantity < 5) {
    //     return `${this.form.quantity} pozycje`;
    //   } else {
    //     return `${this.form.quantity} pozycji`;
    //   }
    // },
  },
  watch: {

  },
  methods: {
    // validateFormTick() {
    //   this.$nextTick(() => {
    //     this.validateForm();
    //   });
    // },
    // validateForm() {
    //   let ret = true;
    //   this.formIsValid = ret;
    //   return ret;
    // },
  },
  created() {
    this.$store.dispatch('fetchMyOrder', {id: this.id}).then(() => {
      this.order = {...this.orderDetails[this.id]};
    });
  }
}
</script>

<style scoped lang="scss">
.flex-fill {
  flex: 1;
}
.col-1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .col {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 15px;
    max-width: 600px;
    width: calc(100% - 30px);
    //&:last-of-type {
    //  padding-left: 0;
    //}
    h4 {
      font-weight: 500;
    }
  }
  .menu_container {
    flex: 2;
  }
  .info_container {
    padding-top: 15px;
    .info_title {
      display: flex;
      margin-top: 0;
      margin-bottom: 2px;
      font-weight: 500;
    }
    .info_line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0;
      margin-bottom: 2px;
      text-align: left;
      p {
        margin: 0;
        a {
          color: var(--text);
          text-decoration: none;
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
.product_title {
  font-weight: 500;
}
.product_price {
  font-weight: 500;
  //color: var(--primary);
}
.image_container {
  border-radius: var(--border-radius);
  overflow: hidden;
  max-height: 200px;
  margin-bottom: 15px;
  position: relative;
  transition: max-height .4s ease;
  .image_logo {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 64px;
    height: 64px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: var(--shadow-one);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  > img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
    transition: max-height .4s ease;
  }
  &:hover {
    max-height: 300px;
    img {
      max-height: 300px;
    }
  }
}
.clickable {
  cursor: pointer;
  transition: background-color .2s ease;
  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }
}
.alternative-text {
  color: var(--text-secondary);
}
.p-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hr {
  width: calc(100% - 30px);
  padding-left: 15px;
  padding-right: 15px;
  height: 1px;
  background-color: var(--border);
  border-radius: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.payment_method {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  flex: 1;
  > div {
    font-weight: 500;
  }
  img {
    width: 50px;
    height: 30px;
    object-fit: contain;
  }
}
.price_to_pay {
  font-weight: bold;
  color: var(--primary);
}
.price_to_pay_title {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
